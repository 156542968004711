import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
    TrimbleIdentityCallbackComponent
} from './@auth/components/trimble-identity-callback/trimble-identity-callback.component';
import { HTTP_INTERCEPTORS, HttpRequest } from '@angular/common/http';
import {SharedModule} from './@core/shared.module';
import {TIdService} from './@auth/services/tId.service';
import {TIdTokenService} from './@auth/services/tId.token.service';
import {environment} from '../environments/environment';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {ModusAngularComponentsModule} from '@trimble-oss/modus-angular-components';
import {TranslocoRootModule} from './transloco-root.module';
import {TranslateService} from '@ngx-translate/core';

import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeEn);
registerLocaleData(localeFr);
registerLocaleData(localeEs);
import {
    NoHttpInterceptorHeader
} from './@auth/auth-interceptor';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations"
import {ToastrModule} from 'ngx-toastr';
import {LayoutService} from './@core/services/layout.service';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {registerLocaleData} from '@angular/common';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {SiteNavEffects} from './state/sitenav/sitenav.effects';
import {siteMapReducer} from './state/sitenav/sitenav.reducer';
import {accessRequestReducer} from './Components/access-request/state/accessRequest.reducer';
import {accessRequestEffects} from './Components/access-request/state/accessRequest.effects';
import {NgMultiSelectDropDownModule} from './@core/components/multi-select-dropdown-ext';
import {routerReducer, StoreRouterConnectingModule} from '@ngrx/router-store';

const appConfiguration = Object.freeze({
    tidIssuer: environment.tidIssuer,
    tidScope: environment.tidScope,
    tidResponseType: environment.tidResponseType,
    tidClientId: environment.tidClientId,
    tidAuthorizeUrl: environment.tidAuthorizeUrl,
    tidLogoutUrl: environment.tidLogoutUrl,
    tidLogoutRedirectUrl: environment.tidLogoutRedirectUrl,
    tidRedirectUrl: environment.tidRedirectUrl,
    tidTokenUrl: environment.tidTokenUrl,
    ccxAuthUrl: environment.ccxAuthUrl,
    ccxUrl: environment.ccxUrl,
    coreApiUrl: environment.coreApiUrl,
    umbracoHost: environment.umbracoHostUrl,
    graphQlCmsApiUrl: environment.graphQlCmsApiUrl,
    dashboards: environment.dashboards
});


@NgModule({
    declarations: [
        AppComponent,
        TrimbleIdentityCallbackComponent,

    ],
    imports: [
        AppRoutingModule,
        ModusAngularComponentsModule,
        AngularSvgIconModule.forRoot(),
        SharedModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        TranslocoRootModule,
        BrowserAnimationsModule,
        StoreModule.forRoot({
            siteNav: siteMapReducer,
            accessRequest: accessRequestReducer,
            router: routerReducer
        }),
        StoreRouterConnectingModule.forRoot(),
        StoreDevtoolsModule.instrument({maxAge: 25}),
        EffectsModule.forRoot([SiteNavEffects, accessRequestEffects]),
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-top-center',
            preventDuplicates: true,
            closeButton: true
        }),

    ],
    providers: [
        TIdService,
        TIdTokenService,
        {provide: 'CONFIG', useValue: appConfiguration},
        {provide: MAT_DATE_LOCALE, useValue: 'en-US'}, TranslateService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {
}


export function filterRequestForAuthInterceptor(req: HttpRequest<any>) {
    if (req.headers.has(NoHttpInterceptorHeader)) {
        return true;
    }
    return ['/api/auth/token']
        .some(url => req.url.includes(url));
}
