import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {IconsRegistryService} from './@core/services/iconsRegistry.service';
import {ContentService} from './@core/services/content.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'trimble-ccx-clientapp-ag';
    tokenSubscription!: Subscription;
    user: { email: string, givenName: string } | null = null;

    constructor(private router: Router,
                private _registry: IconsRegistryService,
                private contentService: ContentService) {
    }

    ngOnInit(): void {
        this._registry.addSvgs();
        this.contentService.loadCurrentLanguage();
    }

    ngOnDestroy(): void {
        this.tokenSubscription.unsubscribe();
    }
}
