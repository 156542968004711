import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    BeefProtocolPagesGQL,
    BeefPageGQL,
    NitrogenProtocolPagesGQL,
    ModuleValidationGQL,
    PageNotFoundGQL,
    BasePage
} from '../../@core/generated/operations-cms-graphql';
import * as SiteMapActions from './sitenav.actions';
import {map, switchMap, tap} from 'rxjs/operators';
import {filter, first, of} from 'rxjs';
import {Router} from '@angular/router';

@Injectable()

export class SiteNavEffects {

    loadSiteMap$ = createEffect(() => this.actions$.pipe(
        ofType(SiteMapActions.SiteMapLoad),
        switchMap(action => {
            return this.beefProtocolPages.watch(
                {
                    protocolPageKeys: action.contentRootKey,
                    lang: action.lang
                }).valueChanges.pipe(
                first(),
                map(e => e.data),
                switchMap(rootPages => {
                    if (!rootPages) return of(SiteMapActions.SiteMapSuccess({contentRootKeys: []}));
                    let protocolChilds: (number | null)[] | null | undefined = [];

                    if (rootPages.beefPage) {
                        protocolChilds = rootPages.beefPage[0]?.childIds;
                    }

                    return this.beefProtocolPages.watch({
                        protocolPageIds: protocolChilds,
                        lang: action.lang,
                    }).valueChanges.pipe(
                        first(),
                        map(e => {
                            return e.data;
                        }),
                        map(childPages => {
                            if (!(rootPages && childPages)) {
                                return SiteMapActions.SiteMapSuccess({contentRootKeys: []});
                            }
                            return SiteMapActions.SiteMapSuccess({
                                contentRootKeys: [
                                    ...rootPages.beefPage?.map(e => this.replaceNavigationUrlVariables(e, action.protocolId)) ?? [],
                                    ...childPages.beefPage?.map(e => this.replaceNavigationUrlVariables(e, action.protocolId)) ?? []]
                            });
                        })
                    )
                })
            );
        })
    ));

    replaceNavigationUrlVariables(n: BasePage | null, protocolId: string | null) {
        if (n && protocolId) {
            const navigationPath = n.navigationPath?.replace('{{protocolId}}', protocolId);
            return {
                ...n,
                navigationPath: navigationPath
            } as BasePage;
        }
        return n;
    }

    loadModuleValidation$ = createEffect(() => this.actions$.pipe(
        ofType(SiteMapActions.ModuleValidationLoad),
        switchMap(action => {
            return this.moduleValidation.watch({
                key: action.moduleKey,
                lang: action.lang
            }).valueChanges.pipe(
                first(),
                map(e => e.data),
                map(moduleValidation => {
                    return SiteMapActions.ModuleValidationSuccess({moduleValidation: moduleValidation.moduleValidation ?? null});
                })
            )
        })
    ));

    loadSwitchRootKey$ = createEffect(() => this.actions$.pipe(
        ofType(SiteMapActions.SwitchMapRootLoad),
        map(value => {
            return SiteMapActions.SiteMapLoad({
                contentRootKey: value.newRootKey,
                lang: value.lang,
                protocolId: value.protocolId
            })
        })
    ));

    PageErrorInfo$ = createEffect(() => this.actions$.pipe(
        ofType(SiteMapActions.PageErrorInfoLoad),
        switchMap(action => {
            return this.pageErrorInfo.watch({
                keys: action.pageKey,
                lang: action.lang
            }).valueChanges.pipe(
                first(),
                map(e => e.data),
                map(pageErrorInfo => {
                    return SiteMapActions.PageErrorInfoSuccess({pageErrorInfo: pageErrorInfo.pageNotFound ?? null});
                })
            )
        })
    ));

    constructor(private actions$: Actions,
                private beefProtocolPages: BeefProtocolPagesGQL,
                private moduleValidation: ModuleValidationGQL,
                private pageErrorInfo: PageNotFoundGQL,
                private router: Router,
    ) {
    }
}
