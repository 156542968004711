import {AccessRequestByDate, OrganizationsQuery} from "src/app/@core/generated/operations-core-graphql";
import * as AppState from '../../../state/app.state';
import {createFeature, createReducer, on} from "@ngrx/store";
import {accessRequestActions} from ".";

export interface State extends AppState.State {
    accessRequest: accessRequestState
}

export interface accessRequestState {
    orgList: OrganizationsQuery | null,
    isWorking: boolean,
    orgListCB: { display: string }[] | null
    accessRequestList: AccessRequestByDate[] | null
}

const initialState: accessRequestState =
    {
        orgList: null,
        accessRequestList: null,
        isWorking: false,
        orgListCB: null
    }


export const accessRequestReducer = createReducer<accessRequestState>(
    initialState,
    on(accessRequestActions.orgListLoad, (state): accessRequestState => {
        return {
            ...state,
            isWorking: true
        }
    }),
    on(accessRequestActions.orgListSuccess, (state, action): accessRequestState => {
        return {
            ...state,
            orgList: action.orgList,
            orgListCB: action.orgList.organizations.map(m => {
                return {
                    display: m?.name || '',
                    id: m?.id
                }
            }) || [],
            isWorking: false
        };
    }),
    on(accessRequestActions.AccessRequestAddOrgUserLoad, (state, action): accessRequestState => {
        return {
            ...state,
            isWorking: true
        }
    }),
    on(accessRequestActions.AccessRequestAddOrgUserSuccess, (state, action): accessRequestState => {
        return {
            ...state,
            isWorking: false,
        }
    }),
    on(accessRequestActions.AccessRequestAddOrgUserFail, (state, action): accessRequestState => {
        return {
            ...state,
            isWorking: false
        }
    }),
    on(accessRequestActions.AccessRequestByDateLoad, (state): accessRequestState => {
        return {
            ...state,
            isWorking: true
        }
    }),
    on(accessRequestActions.AccessRequestByDateSuccess, (state, action): accessRequestState => {
        return {
            ...state,
            accessRequestList: action.result,
            isWorking: false
        }
    }),
    on(accessRequestActions.AccessRequestRejectOrgUserLoad, (state, action): accessRequestState => {
        return {
            ...state,
            isWorking: true
        }
    }),
    on(accessRequestActions.AccessRequestRejectOrgUserSuccess, (state, action): accessRequestState => {
        return {
            ...state,
            isWorking: false
        }
    }),
    on(accessRequestActions.AccessRequestRejectOrgUserFail, (state, action): accessRequestState => {
        return {
            ...state,
            isWorking: false
        }
    }),
);


export const accessRequestFeature = createFeature({
    name: 'accessRequest',
    reducer: accessRequestReducer
})

export const {
    name,
    reducer,
    selectAccessRequestState
} = accessRequestFeature;
