import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TIdService} from '../../services/tId.service';
import {WINDOW_REF} from '../../../@core/shared.module';

@Component({
    selector: 'app-trimble-identity-callback',
    styleUrls: ['trimble-identity-callback.component.scss'],
    templateUrl: 'trimble-identity-callback.component.html'
})
export class TrimbleIdentityCallbackComponent implements OnInit {


    constructor(private tidService: TIdService, private route: ActivatedRoute, private router: Router,
                @Inject(WINDOW_REF) protected window: any) {
    }

    ngOnInit(): void {
        const code: string = this.route.snapshot.queryParams['code'];
        const identityProvider: string = this.route.snapshot.queryParams['identity_provider'];
        this.tidService.authenticate({code, identityProvider})
            .subscribe(e => {
                    if (e.getIdPayload().ccxAccess === "active") {
                        this.window.location.href = '/';
                    } else {
                        this.router.navigate(['/invitation']);
                    }
                }
            );
    }
}
