import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TIdService} from '../../services/tId.service';
import {WINDOW_REF} from 'src/app/@core/shared.module';

@Component({
    templateUrl: 'trimble-auth-logout.component.html'
})
export class TrimbleAuthLogoutComponent implements OnInit {

    constructor(
        private router: Router) {
    }

    ngOnInit(): void {
        this.router.navigate(['/'])
    }
}
