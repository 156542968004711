import {inject, Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild, CanActivateFn, Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {TIdService} from './services/tId.service';
import {map, switchMap} from 'rxjs/operators';

export function authenticatedGuard(): CanActivateFn {
    return (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
        const tidService = inject(TIdService);
        return tidService.isAuthenticated()
            .pipe(
                switchMap(e => {
                    if (!e) {
                        tidService.authenticate(null);
                        return of(false);
                    }
                    return of(true);
                })
            );
    }
}

export function newUserGuard(): CanActivateFn {
    return (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
        const tidService = inject(TIdService);
        const routerSvc = inject(Router);
        return tidService.isActive([]).pipe(
            switchMap(hasRole => {
                if (!hasRole) {
                    return routerSvc.navigate(['/invitation']);
                }
                return of(hasRole);
            })
        );
    }
}

// export function hasRolesGuard(roles: string[]): CanActivateFn {
//     return (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> => {
//         const tidService = inject(TIdService);
//         const routerSvc = inject(Router);
//         return tidService.hasRole(roles).pipe(
//             switchMap(hasRole => {
//                 if (!hasRole) {
//                     return routerSvc.navigate(['/invitation']);
//                 }
//                 return of(hasRole);
//             })
//         );
//     }
// }
