import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {accessRequestActions} from './index';
import {concatMap, of, pipe, tap} from 'rxjs';
import {catchError, filter, first, map, switchMap} from 'rxjs/operators';
import {    
    AddOrganizationToUserGQL,
    OrganizationsGQL,
    RejectAccesRequestGQL,
    UsersRequestingByDateGQL
} from 'src/app/@core/generated/operations-core-graphql';
import {ToastrService} from 'ngx-toastr';
import {ContentService} from 'src/app/@core/services/content.service';
import {handleApolloTemplateError, handleApolloTemplateSuccess} from 'src/app/@core/utils';
import {ApolloError} from '@apollo/client/core';


@Injectable()
export class accessRequestEffects {

    constructor(private actions$: Actions,
                private loadOrganizations: OrganizationsGQL,
                private addOrganizationtoUser: AddOrganizationToUserGQL,
                private usersRequestingByDate: UsersRequestingByDateGQL,
                private rejectAccesRequest: RejectAccesRequestGQL,
                private toast: ToastrService,
                private contSrvc: ContentService) {
    }


    loadOrganizations$ = createEffect(() => this.actions$  // 2 define effect
        .pipe(
            ofType(accessRequestActions.orgListLoad), // 3 filter actions
            switchMap(action => // 4 map
                this.loadOrganizations.watch() // 5 call service
                    .valueChanges
                    .pipe(
                        first(),
                        map(e => accessRequestActions.orgListSuccess({orgList: e.data})) // 6 return action
                    ))
        ));


    addOrganization$ = createEffect(() => this.actions$
        .pipe(
            ofType(accessRequestActions.AccessRequestAddOrgUserLoad),
            concatMap(action =>
                this.addOrganizationtoUser.mutate({input: action.input})
                    .pipe(
                        map(e => accessRequestActions.AccessRequestAddOrgUserSuccess(
                            {
                                result: {
                                    __typename: 'CoreMutation',
                                    addOrganization: e.data?.addOrganizationToUser,
                                    extenssions: e.extensions
                                }
                            }
                        )),
                        catchError((err: ApolloError) => {
                            handleApolloTemplateError(err, this.toast, this.contSrvc);
                            return of(accessRequestActions.AccessRequestAddOrgUserFail({errors: err}));
                        })
                    ))
        ))

    organizationAddedSuccess = createEffect(() => this.actions$
        .pipe(
            ofType(accessRequestActions.AccessRequestAddOrgUserSuccess),
            tap((action) => {
                handleApolloTemplateSuccess(action.result.extenssions['msgs'][0], this.toast, this.contSrvc);
            }),
            switchMap(action => // 4 map
                this.usersRequestingByDate.watch() // 5 call service
                    .valueChanges
                    .pipe(
                        first(),
                        map(e => accessRequestActions.AccessRequestByDateSuccess({result: e.data.usersRequestingByDate})) // 6 return action
                    ))
        ), {dispatch: false});


    rejectUserRequest$ = createEffect(() => this.actions$
        .pipe(
            ofType(accessRequestActions.AccessRequestRejectOrgUserLoad),
            concatMap(action =>
                this.rejectAccesRequest.mutate({input: action.input})
                    .pipe(
                        map(e => accessRequestActions.AccessRequestRejectOrgUserSuccess(
                            {
                                result: {
                                    __typename: 'CoreMutation',
                                    addOrganization: e.data?.rejectAccesRequest,
                                    extenssions: e.extensions
                                }
                            }
                        )),
                        catchError((err: ApolloError) => {
                            handleApolloTemplateError(err, this.toast, this.contSrvc);
                            return of(accessRequestActions.AccessRequestRejectOrgUserFail({errors: err}));
                        })
                    )),
            switchMap(action => // 4 map
                this.usersRequestingByDate.watch() // 5 call service
                    .valueChanges
                    .pipe(
                        first(),
                        map(e => accessRequestActions.AccessRequestByDateSuccess({result: e.data.usersRequestingByDate})) // 6 return action
                    ))
        ));


    loadaccessRequestByDate$ = createEffect(() => this.actions$
        .pipe(
            ofType(accessRequestActions.AccessRequestByDateLoad), // 3 filter actions
            switchMap(action => // 4 map
                this.usersRequestingByDate.watch() // 5 call service
                    .valueChanges
                    .pipe(
                        first(),
                        map(e => accessRequestActions.AccessRequestByDateSuccess({result: e.data.usersRequestingByDate})) // 6 return action
                    ))
        ));
}
